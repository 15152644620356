import * as React from "react";
import { IconProps } from "..";

const Instagram = React.forwardRef<SVGSVGElement, IconProps>(
  ({ title = "Instagram", titleId = "instagram", ...props }, svgRef) => {
    return (
      <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={svgRef}
        aria-labelledby={titleId}
        {...props}
      >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
          d="M17.5 20h-15c-.69 0-1.28-.244-1.768-.732A2.409 2.409 0 010 17.5v-15C0 1.81.244 1.22.732.732A2.409 2.409 0 012.5 0h15c.69 0 1.28.244 1.768.732.488.489.732 1.078.732 1.768v15c0 .69-.244 1.28-.732 1.768A2.409 2.409 0 0117.5 20zM10 7.5c-.69 0-1.28.244-1.768.732A2.409 2.409 0 007.5 10c0 .69.244 1.28.732 1.768A2.409 2.409 0 0010 12.5c.69 0 1.28-.244 1.768-.732A2.409 2.409 0 0012.5 10c0-.69-.244-1.28-.732-1.768A2.409 2.409 0 0010 7.5zm7.5-4.375a.6.6 0 00-.186-.44.6.6 0 00-.439-.185h-2.5a.6.6 0 00-.44.186.6.6 0 00-.185.439v2.5a.6.6 0 00.185.44.6.6 0 00.44.185h2.5a.6.6 0 00.44-.186.6.6 0 00.185-.439v-2.5zm0 6.25a.6.6 0 00-.186-.44.6.6 0 00-.439-.185h-1.25a.6.6 0 00-.44.186.6.6 0 00-.185.439V10c0 1.38-.488 2.559-1.465 3.535C12.56 14.512 11.38 15 10 15c-1.38 0-2.559-.488-3.535-1.465C5.488 12.56 5 11.38 5 10v-.625a.6.6 0 00-.186-.44.6.6 0 00-.439-.185h-1.25a.6.6 0 00-.44.186.6.6 0 00-.185.439v7.5a.6.6 0 00.186.44.6.6 0 00.439.185h13.75a.6.6 0 00.44-.186.6.6 0 00.185-.439v-7.5z"
          fill="currentColor"
        />
      </svg>
    );
  }
);
export default Instagram;
